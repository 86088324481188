<template>
  <section>
    <div style="position: relative; height:100vh;" class="mt-0 mb-4">
      <!-- HERO -->
      <section class="hero is-halfheight">
        <div class="hero-img"></div>
      </section>
      <!-- HERO CONTENT -->
      <div
        class="has-text-centered hero-content"
      >
        <h1 class="title has-text-primary is-size-3-mobile is-size-2-tablet is-size-1-fullhd">{{$t('brand_name')}}</h1>
        <h2 class="subtitle p-2 has-text-primary is-size-4-mobile is-size-3-tablet is-size-2-fullhd">{{$t('brand_desc')}}</h2>
        <div class="is-centered buttons">
          <p style="border-radius: 50px; padding: 0.5rem 1.5rem" class="p is-size-5 has-background-primary m-2 is-medium">{{$t('hero_tag_1')}}</p>
          <p style="border-radius: 50px; padding: 0.5rem 1.5rem" class="p is-size-5 has-background-primary m-2 is-medium">{{$t('hero_tag_2')}}</p>
          <p style="border-radius: 50px; padding: 0.5rem 1.5rem" class="p is-size-5 has-background-primary m-2 is-medium">{{$t('hero_tag_3')}}</p>
        </div>
      </div>
    </div>


    <!-- ABOUT -->
    <div class="max-width m-auto m-1 pt-6 pb-6 section">
      <div class="columns ml-0 mr-0 is-justify-content-space-between">
        <div class="column is-12-mobile is-6-tablet is-flex is-flex-direction-column is-justify-content-center" style="padding-left: 0">
        <h2 class="title mb-2">{{$t('home_about_title')}}</h2>
        <underline class="mb-4"/>
        <p>{{$t('home_about_subtitle_1')}}</p>
          <p>{{$t('home_about_subtitle_2')}}</p>
            <div class="content">
            <blockquote class="has-background-primary-light" style="border-left: 5px solid var(--primary);">“{{$t('home_about_qoute')}}” <p>– {{$t('home_about_qoute_author')}}</p></blockquote>
            </div>
          <!-- <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iusto dolorum voluptatem nulla corporis maxime impedit est ab soluta? Vero culpa sequi reprehenderit non, fugiat id aliquid unde alias molestiae consectetur.</p> -->
        </div>
          <div class="column has-background-white is-12-mobile is-4-tablet is-flex is-justify-content-center is-align-items-center">
            <img src="@/assets/img/lily_pads.jpg">
        </div>
      </div>
    </div>

    <!-- CAMPER HIGHLIGHTS -->
  <div class="pt-6 pb-6">
    <div class="is-light highlight-background">
         <div class="pt-6 pb-6" style="background-color: rgba(0, 0, 0, 0.767);">
      <div class="columns max-width m-auto  ml-0 mr-0 is-justify-content-space-between section">
          <div class="column has-background-white is-12-mobile is-4-tablet is-flex is-justify-content-center is-align-items-center">
            <img class="" src="@/assets/img/camper/camper_1.jpg">
        </div>
        <div class="column is-12-mobile is-6-tablet is-flex is-flex-direction-column is-justify-content-center">
        <h2 class="title mb-2 has-text-white">{{$t('home_camper')}}</h2>
        <underline class="mb-4"/>
        <p class="has-text-white">{{$t('home_camper_description_1')}}</p>
          <p class="has-text-white block">{{$t('home_camper_description_2')}}</p>
          <p class="has-text-white block">{{$t('home_camper_description_3')}}</p>
          <h2 class="subtitle is-size-4 mb-2 mt-2 has-text-white">{{$t('home_camper_options')}}</h2>

          <div class="tags mt-4 mb-4">
              <span v-for="tag in jsonData.camper_tags" :key="tag" class="tag is-large">
                <span class="icon">
                <i :class="tag.icon"></i>
              </span>
              <p>{{tag.tag}}</p>
              </span>
          </div>
        </div>
      </div>
    </div>
    </div>

  </div>


  

    <!-- CARDS -->
    <div class="has-background-white pt-6 pb-6 spacing section">
      <div class="pt-4 pb-4 max-width m-auto">
        <h2 class="title mb-2">{{$t('home_cards_title')}}</h2>
          <underline class="mb-4"/>
        <div class="max-width ml-auto mr-auto columns is-multiline">
          <div v-for="card in cards" :key="card"  class="column is-4">
              <hover-card
              :card="card"/>
          </div>
        </div>
      </div>
    </div>

    <container class="pt-6 pb-6 has-background-white" :title="$t('text_gallery') ">
      <Carousel :autoplay="5000" :wrap-around="true" :mouseDrag="false" :touchDrag="false">
        <slide class="slide "  v-for="slide in jsonData.carousel" :key="slide">
          <img style="-webkit-backface-visibility: hidden;" :src="require(`@/assets/img/carousel/${slide}`)" alt="">
        </slide>
          <template  #addons>
            <pagination />
          </template>
      </Carousel>
    </container>
    

    <div style="max-width: 50rem;" class="m-auto vuecal--green-theme">
      <div class="is-8 ml-auto" style="margin-top: 10rem;">
        <h2 class="title mb-2 has-text-black">{{$t('availability_title')}}</h2>
        <underline class="mb-4"/>
        <vueCal
            :locale="$i18n.locale === `est` ? 'et' : 'en'"
            :time="false"
            events-on-month-view
            :events="bookedDates"
            active-view="month"
            :min-date="new Date().toISOString().substr(0, 10)"
            class="landing-calender"
            xsmall>
            <template #arrow-prev>
              <i class="icon material-icons">&lt;</i>
            </template>
            <template #arrow-next>
              <i class="icon material-icons">&gt;</i>
            </template>
          </vueCal>
    </div>
    </div>

      <div class="pt-6 pb-6" style="margin-top: 10rem;">
    <div class="is-light review-background">
         <div class=" pt-6 pb-6" style="background-color: rgba(0, 0, 0, 0.767);">
      <div class="columns max-width m-auto  ml-0 mr-0 is-justify-content-space-between section">

        <div class="column is-12-mobile is-6-tablet is-flex is-flex-direction-column is-justify-content-center">
          <h2 class="title mb-2 has-text-white">{{$t('home_reviews_title')}}</h2>
          <underline class="mb-4"/>
          <p class="has-text-white">{{$t('home_reviews_subtitle')}}</p>
        </div>

          <div class="column is-12-mobile is-6-tablet is-flex is-justify-content-center is-align-items-center">
            <div v-if="reviews.length != 0">
              <Carousel :autoplay="5000" :wrap-around="true" :mouseDrag="false" :touchDrag="false">
                <slide class="slide" style="height:10rem"  v-for="review in reviews" :key="review">
                  <div class="is-flex is-flex-direction-column" style="width:80%">
                    <p class="mt-2 mb-2 has-text-white">{{review.message}}</p>
                      <div class="is-flex is-justify-content-space-between">
                        <p class="has-text-primary" v-if="review.author">{{review.author}}</p>
                        <p class="has-text-primary" v-else>{{$t('home_reviews_anonymous')}}</p>
                        <div>
                          <span class="fa fa-star" :class="review.rating >= 1 ? 'checked' : ''"></span>
                          <span class="fa fa-star" :class="review.rating >= 2 ? 'checked' : ''"></span>
                          <span class="fa fa-star" :class="review.rating >= 3 ? 'checked' : ''"></span>
                          <span class="fa fa-star" :class="review.rating >= 4 ? 'checked' : ''"></span>
                          <span class="fa fa-star" :class="review.rating >= 5 ? 'checked' : ''"></span>
                        </div>
                      </div>
                    <p class="has-text-left has-text-white">{{review.date}}</p>
                  </div>
                </slide>
              </Carousel>
            </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  </section>
</template>

<script>
import data from '../../data/data.json'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination} from 'vue3-carousel';
import VueCal from 'vue-cal'
import {getData, getBookedDates} from "../../firebase/FirestoreAPI";
import 'vue-cal/dist/vuecal.css'
export default {
  props: {},
  setup() {},
  components: {
    Carousel,
    Slide,
    Pagination,
    VueCal
  },
  data() {
    return {
      jsonData: data,
      reviews: "",
      review: {},
      bookedDates: [],
      cards: [
        { 
          content: "Camper",
          image: "rent_camper_1.jpg",
          desc_small: "Rent a camper for a most enjoyable night!"
        },
        { 
          content: "Textile cleaner",
          image: "vacuum/vacuum_1.jpg",
          desc_small: "Rent a textile cleaner for you car, carpets or more!"
        },
        { 
          content: "Tent sauna",
          image: "saunatent/sauna_tent_1.jpg",
          desc_small: "Rent a portable tent sauna"
        }
      ],
      db: process.env.VUE_APP_BASE_API, 
      reviewForm: true
    };
  },

  methods: {
        getDate(date) {
          date = date.split('T')
          return date[0]
        },
        async getReviews() {
          let res = await getData("reviews")
          if(res){
            this.reviews = res.filter((e) => e.approved === true )
          }
        },
        async getBookings(){
          let data = undefined
            data = await getBookedDates()

            data = data.map((i) => {
              // delete i.title
              delete i.content
              return i
            })
            
            if(data) {
              this.bookedDates = data;
       
            }
        },

  },

  computed: {
    checkReview() {
      if(this.review.message && this.review.rating) {
        return false 
      } else {
        return true
      }
    }
  },

  mounted() {
    this.getReviews();
    this.getBookings()
      window.scrollTo({
      top: 0,
      left: 0,
    });
  },
};
</script>

<style lang="scss" scoped>
.hero-img{
    background-image: url('~@/assets/img/hero_1.jpg');
    background-position: 85%;
    background-position-y: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
}
.hero-content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: fit-content;
    transition: 0.125s ease all;

  @media (min-width: 1024px) {
    left: 65%;
    transition: 0.125s ease all;
  }
}
.columns{
  height: 100%;
}

.slide{
  height: 20rem;
  @media (min-width: 1024px) {
    height: 50vh;
  }
  @media (min-width: 1024px) {
    height: 70vh;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 90%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  } 
}
.highlight-background{
  background-image: url('~@/assets/img/rent_camper_1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.review-background{
  background-image: url('~@/assets/img/review_background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.carousel__slide--active {
  z-index: 2;
}
.carousel__slide {
  z-index: 1;
}
.checked {
  color: orange;
}
</style>
<style lang="scss">
.vuecal{
  height: fit-content!important;
}
.vuecal__cell{
      color: green;
        &--disabled {
      text-decoration: line-through;
      color: red;
      }
      &--has-events {background-color: rgba(255, 0, 0, 0.45);}
      &-events-count {display: none;}
      
      }
      .landing-calender{
        .vuecal__cell--has-events{
          cursor: no-drop;
        }
      }
</style>